import { requestAppPost } from "@/db/dbManag";
import { API } from "@/enums/costanti";
import { msalInstance } from "@/authConfig";
import { alertFailed } from "@/functions/swAlert";
const getDefaultState = () => {
  return {
    id_year: localStorage.getItem("id_year")
      ? parseInt(localStorage.getItem("id_year"))
      : 0,
    id_event: localStorage.getItem("id_event")
      ? parseInt(localStorage.getItem("id_event"))
      : 0,
    years: [],
    events: [],
    loadedLookups: false,
    statusLookups: 0,
    configurazioni: null,
    loadedConfigurazioni: false,
  };
};

const state = getDefaultState();

const getters = {
  years: (state) => state.years,
  events: (state) => state.events,
  id_year: (state) => state.id_year,
  id_event: (state) => state.id_event,
  loadedLookups: (state) => state.loadedLookups,
  statusLookups: (state) => state.statusLookups,
  configurazioni: (state) => state.configurazioni,
  CONFIG_MAX_GUEST_SESSION: (state) =>
    state.configurazioni ? state.configurazioni.max_guest_sessions : null,
  CONFIG_MINORS: (state) => state.configurazioni.minors,
  loadedConfigurazioni: (state) => state.loadedConfigurazioni,
};
const actions = {
  getLookupsEventoAnno: async (storeObj) => {
    storeObj.commit("resetLookups");
    await requestAppPost(API.LOOKUP_GET, { keys: "ee|yy" }).then((res) => {
      
      if (res.status == 200) {
        if (!localStorage.getItem("id_event")) {
          storeObj.commit("setElement", {
            el: "id_event",
            value: res.data.result[0].events[0].id,
          });
        }
        if (!localStorage.getItem("id_year")) {
          storeObj.commit("setElement", {
            el: "id_year",
            value: res.data.result[1].years[0].id,
          });
        }
        storeObj.commit("setLookups", res);
        return res;
      } else {
        alertFailed(
          "Attenzione! Si è verificato un errore. Riprovare più tardi"
        ).then(() => {
          msalInstance.logoutRedirect();
        });
      }
    });
  },
  getConfigurazioniEvento: async (storeObj) => {
    
    if (storeObj.getters.configurazioni) {
      return;
    }
    const res = await requestAppPost(API.CONFIGURATION_EVENT_YEAR_VIEW, {
      id_event: localStorage.getItem("id_event"),
      id_year: localStorage.getItem("id_year"),
    }).then((res) => {
      if (res.status == 200) {
        return res;
      } else {
        alertFailed(
          "Attenzione! Si è verificato un errore nel reperire le configurazioni per l'evento selezionato. Riprovare più tardi"
        );
        // .then(() => {
        //   msalInstance.logoutRedirect();
        // });
      }
    });

    storeObj.commit("setConfigurazioni", res);
  },
};
const mutations = {
  setLookups: (state, res) => {
    state.statusLookups = res.status;
    state.events = res.data.result[0].events;
    state.years = res.data.result[1].years;
    state.loadedMenu = true;
  },
  setElement: (state, { el, value }) => {
    state[el] = value;
    localStorage.setItem(`${el}`, value);
  },
  setConfigurazioni: (state, res) => {
    
    state.configurazioni = res.data.results[0];
    state.loadedConfigurazioni = true;
    
  },
  resetLookups: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
