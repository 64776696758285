import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { requestAppPost, requestAppGet } from "@/db/dbManag";

export const alertSuccess = (message) => {
  return Swal.fire({
    html: message,
    icon: "success",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn btn-danger border rounded",
    },
    confirmButtonText: "Chiudi",
    allowOutsideClick: false,
  }).then(() => {
    return true;
  });
};

export const alertFailed = (message) => {
  return Swal.fire({
    html: message,
    icon: "error",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn btn-danger border rounded",
    },
    confirmButtonText: "Chiudi",
    allowOutsideClick: false,
  }).then(() => {
    return true;
  });
};
export const alertFailedNoClose = (message) => {
  return Swal.fire({
    html: message,
    icon: "error",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn btn-danger border rounded",
    },
    allowOutsideClick: false,
  }).then(() => {
    return true;
  });
};

export const callFunctionAfterAlert = async (
  endpoint,
  payload,
  question,
  textAlertSuccess,
  textAlertFailed,
  nonMostrarePopup
) => {
  return Swal.fire({
    html: question ? question : "Sei sicuro?",
    confirmButtonText: "Conferma",
    showCancelButton: true,
    icon: "warning",
    cancelButtonText: "Annulla",
    allowOutsideClick: false,
    showLoaderOnConfirm: true,
    customClass: {
      confirmButton: "btn fw-bold btn-light-primary",
      cancelButton: "btn fw-bold btn-light-danger",
    },
    preConfirm: () => {
      return requestAppPost(endpoint, payload).then(
        ({ data, status: reqStatus, statusText }) => {
          return { data, status: reqStatus, statusText };
        }
      );
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      const { data, status: reqStatus } = result.value;
      if (
        (reqStatus != 200 && reqStatus != 204) ||
        (reqStatus == 200 &&
          data.results &&
          data.results[0] &&
          data.results[0].error)
      ) {
        nonMostrarePopup
          ? ""
          : data.results[0].error
          ? alertComposto(data.results, data.record)
          : alertFailed(data.message ? data.message : textAlertFailed);
        return data.record ? true : false;
      } else {
        nonMostrarePopup ? "" : alertSuccess(textAlertSuccess);
        return data;
      }
    } else {
      return false;
    }
  });
};
export const callFunctionAfterAlertGet = async (
  endpoint,
  params,
  question,
  textAlertSuccess,
  textAlertFailed,
  nonMostrarePopup
) => {
  return Swal.fire({
    html: question ? question : "Sei sicuro?",
    confirmButtonText: "Conferma",
    showCancelButton: true,
    icon: "warning",
    cancelButtonText: "Annulla",
    allowOutsideClick: false,
    showLoaderOnConfirm: true,
    customClass: {
      confirmButton: "btn fw-bold btn-light-primary",
      cancelButton: "btn fw-bold btn-light-danger",
    },
    preConfirm: () => {
      return requestAppGet(endpoint, params).then(
        ({ data, status: reqStatus, statusText }) => {
          return { data, status: reqStatus, statusText };
        }
      );
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      const { data, status: reqStatus } = result.value;
      if (
        (reqStatus != 200 && reqStatus != 204) ||
        (reqStatus == 200 &&
          data.results &&
          data.results[0] &&
          data.results[0].error)
      ) {
        nonMostrarePopup
          ? ""
          : data.results[0].error
          ? alertComposto(data.results, data.record)
          : alertFailed(data.message ? data.message : textAlertFailed);
        return data.record ? true : false;
      } else {
        nonMostrarePopup ? "" : alertSuccess(textAlertSuccess);
        return data;
      }
    } else {
      return false;
    }
  });
};

export const alertComposto = (errori, persone_invitate) => {
  let testoErrori = `Sono presenti i seguenti errori: <br><br><ul>`;
  errori.forEach((element) => {
    testoErrori = testoErrori + "<li>" + element.error + "</li>";
  });
  testoErrori = testoErrori + "</ul>";
  if (persone_invitate) {
    testoErrori =
      testoErrori +
      "</br> Tutte le altre persone sono state invitate correttamente";
  }
  alertFailed(testoErrori);
};

export const alertChoiceNoFunction = async (question) => {
  return Swal.fire({
    html: question ? question : "Sei sicuro?",
    confirmButtonText: "Conferma",
    showCancelButton: true,
    icon: "warning",
    cancelButtonText: "Annulla",
    allowOutsideClick: false,
    showLoaderOnConfirm: true,
    customClass: {
      confirmButton: "btn fw-bold btn-light-primary",
      cancelButton: "btn fw-bold btn-light-danger",
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      return true;
    } else {
      return false;
    }
  });
};
