import axios from "axios";
import { alertFailed } from "@/functions/swAlert";
import { msalInstance } from "@/authConfig";

export const baseUrlApiApp =
  process.env.VUE_APP_IS_BRANCH_PROD == "1"
    ? process.env.VUE_APP_ENDPOINT_API_PROD
    : process.env.VUE_APP_ENDPOINT_API_TEST;

export const dbManagApp = axios.create({
  baseURL: baseUrlApiApp,
});
dbManagApp.interceptors.request.use((config) => {
  const token = localStorage.getItem("tokenAdb2c");
  if (token) config.headers["Authorization"] = "Bearer " + token;
  return config;
});

export const requestAppPost = async (
  endpoint: string,
  payload: Record<string, unknown>,
  noAlert
) => {
  return dbManagApp
    .post(endpoint, payload)
    .then((res) => {
      if (res.status == 401) {
        // msalInstance.logoutRedirect();
        throw new Error("401");
      }

      return {
        ...res,
      };
    })
    .catch((e) => {
      if (e == "401") {
        alertFailed(
          "Sessione Scaduta. Per continuare, effettuare nuovamente il login"
        ).then(() => msalInstance.logoutRedirect());
      }
      if (!noAlert) {
        alertFailed(
          e.response.data.message
            ? e.response.data.message
            : "Attenzione! Si è verificato un errore. Riprovare più tardi"
        );
      }

      if (e.response.data.message == "Utente non autorizzato") {
        msalInstance.logoutRedirect();
      }

      return {
        ...e.response,
      };
    });
};

export const requestAppPostNoAlert = async (
  endpoint: string,
  payload: Record<string, unknown>
) => {
  return dbManagApp
    .post(endpoint, payload)
    .then((res) => {
      if (res.status == 401) {
        // msalInstance.logoutRedirect();
        throw new Error("401");
      }
      return {
        ...res,
      };
    })
    .catch((e) => {
      if (e == "401") {
        alertFailed(
          "Sessione Scaduta. Per continuare, effettuare nuovamente il login"
        ).then(() => msalInstance.logoutRedirect());
      }
      return {
        ...e.response,
      };
    });
};

let controller;

export const abortRequest = () => {
  controller.abort("Ricerca utente");
};

export const requestAppGet = async (
  endpoint: string,
  params: Record<string, unknown>
) => {
  return dbManagApp
    .get(endpoint, params)
    .then((res) => {
      if (res.status == 401) {
        // msalInstance.logoutRedirect();
        throw new Error("401");
      }
      return {
        ...res,
      };
    })
    .catch((e) => {
      if (e == "401") {
        alertFailed(
          "Sessione Scaduta. Per continuare, effettuare nuovamente il login"
        ).then(() => msalInstance.logoutRedirect());
      } else {
        alertFailed(
          e.response.data.message
            ? e.response.data.message
            : "Attenzione! Si è verificato un errore. Riprovare più tardi"
        );
      }

      return {
        ...e.response,
      };
    });
};

export const sendFile = axios.create({
  baseURL: `${baseUrlApiApp}`,
  // data: bodyFormData,
  headers: { "Content-Type": "multipart/form-data" },
  onUploadProgress: function (progressEvent) {
    console.log("cambio percentuale upload file", progressEvent);
    // let percentCompleted = Math.round(
    //   (progressEvent.loaded * 100) / progressEvent.total
    // );
  },

  /* other custom settings */
});
sendFile.interceptors.request.use((config) => {
  const token = localStorage.getItem("tokenAdb2c");
  if (localStorage.getItem("tokenAdb2c")) {
    config.headers["Authorization"] = "bearer " + token;
  }
  return config;
});
export const fileSender = async (apiLink, bodyFormData) => {
  return sendFile
    .post(apiLink, bodyFormData)
    .then((res) => {
      if (res.status == 401) {
        // msalInstance.logoutRedirect();
        throw new Error("401");
      }
      return {
        ...res,
      };
    })
    .catch((e) => {
      if (e == "401") {
        alertFailed(
          "Sessione Scaduta. Per continuare, effettuare nuovamente il login"
        ).then(() => msalInstance.logoutRedirect());
      } else {
        alertFailed(
          e && e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "Attenzione! Si è verificato un errore. Riprovare più tardi"
        );
      }
      return {
        ...e.response,
      };
    });
};

const dbManagControlloTokenFCH = axios.create({
  baseURL: baseUrlApiApp,
  // headers: {
  //   Authorization: "Bearer " + localStorage.getItem("id_token"),
  // },
  /* other custom settings */
});

export const requests = {
  dbManag: dbManagApp,
  baseUrlApiApp,
  dbManagControlloTokenFCH,
};
