
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const route = useRoute();

    onMounted(async () => {
      const route = useRoute();

      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get("myParam");

      await store.dispatch("getLookupsEventoAnno");
      // if (!urlParams.size) {
      //   await store.dispatch("getConfigurazioniEvento");
      // }

      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      document.addEventListener("show.bs.dropdown", function () {
        const trs = document.querySelectorAll("tr");
        const dropdownButtons = document.querySelectorAll(
          ".bi-three-dots-vertical"
        );
        const dropdownMenus = document.querySelectorAll(".dropdown-menu");

        let includesTallElement = false;
        trs.forEach((element) => {
          if (element.offsetHeight > 100) {
            includesTallElement = true;
          }
        });

        trs.forEach((element, index) => {
          if (index != 0 && trs.length < 8) {
            dropdownMenus.forEach((dropdown, indexdd) => {
              dropdownButtons.forEach((dots, indexdots) => {
                const dotsPosition = dots.getBoundingClientRect();
                if (indexdd === index - 1 && indexdots === index - 1) {
                  const position = () => {
                    dropdown.classList.add("position-fixed");
                    const elementPosition = element.getBoundingClientRect();
                    var style = document.createElement("style");
                    style.type = "text/css";
                    // inset: unset!important;

                    style.innerHTML = `.dropdown-transform${index} { transform: translate3d(-82px, 38px, 0px)!important; inset: ${elementPosition.y}px 0px auto auto!important;  left:${elementPosition.x}px; top: ${elementPosition.y}px`;

                    document.getElementsByTagName("head")[0].appendChild(style);

                    dropdown.classList.add(`dropdown-transform${index}`);
                  };
                  document.addEventListener("scroll", position, true);
                  position();
                }
              });
            });
          }
        });
      });

      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
